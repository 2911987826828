import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Milestone = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <rect
        fill={color}
        height={14}
        rx={4}
        transform="rotate(-45 2.102 12)"
        width={14}
        x={2.102}
        y={12}
      />
    </svg>
  );
});

Milestone.displayName = 'Milestone';

export default Milestone;
