import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const LineChart = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M21 6a1 1 0 0 1-1 1l-3.802 6.583-.203.352-.386-.126-5.798-1.896-4.87 5.75a1 1 0 1 1-.763-.646l5.088-6.007.217-.256.32.104 5.732 1.874L19.134 6.5A1 1 0 1 1 21 6Z"
        clipRule="evenodd"
      />
    </svg>
  );
});

LineChart.displayName = 'LineChart';

export default LineChart;
