import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Export = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M10.871 6.355H5V19h12.645v-5.87"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        d="m10 14 9-9"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M14.032 5H19v4.968"
      />
    </svg>
  );
});

Export.displayName = 'Export';

export default Export;
