import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const PhaseOutline = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M5.55 7h13.566l-5.087 5 5.087 5H5.55V7Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

PhaseOutline.displayName = 'PhaseOutline';

export default PhaseOutline;
