import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const TextInput = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      fill="none"
      height={25}
      ref={ref}
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M12.51 4.453a.5.5 0 0 1 .585-.395c.375.073.936.246 1.417.541.164.101.335.227.488.38a2.67 2.67 0 0 1 .488-.38 4.503 4.503 0 0 1 1.417-.54.5.5 0 0 1 .19.98 3.508 3.508 0 0 0-1.083.412c-.368.226-.512.452-.512.653V13.55H17a.5.5 0 0 1 0 1h-1.5V16.994c0 .17.127.428.444.672.307.235.702.383 1.056.383a.5.5 0 0 1 0 1c-.606 0-1.21-.241-1.664-.59a2.601 2.601 0 0 1-.336-.306 2.601 2.601 0 0 1-.335.306c-.454.349-1.059.59-1.665.59a.5.5 0 1 1 0-1c.354 0 .75-.148 1.056-.383.318-.244.444-.503.444-.673V14.55H13a.5.5 0 1 1 0-1h1.5V6.104c0-.201-.144-.427-.512-.653a3.508 3.508 0 0 0-1.083-.411.5.5 0 0 1-.396-.587Z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        d="M14.012 8.456h-2.974l-1.49 8.593h-1.6l1.49-8.593h-2.96l.24-1.36h7.54l-.246 1.36Z"
      />
    </svg>
  );
});

TextInput.displayName = 'TextInput';

export default TextInput;
