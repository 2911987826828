import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Group = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M11.158 20.478v-.13a4.579 4.579 0 0 0-4.58-4.579v0A4.579 4.579 0 0 0 2 20.35v.129"
        stroke={color}
        strokeLinecap="round"
      />
      <mask
        id="a"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x={7}
        y={8}
        width={10}
        height={3}
      >
        <path fill="#C4C4C4" d="M7 8h10v3H7z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M16.601 13.739v-.13a4.579 4.579 0 0 0-4.579-4.578v0a4.579 4.579 0 0 0-4.579 4.578v.13"
          stroke={color}
          strokeLinecap="round"
        />
      </g>
      <path
        d="M22 20.478v-.13a4.579 4.579 0 0 0-4.58-4.579v0a4.579 4.579 0 0 0-4.578 4.58v.129"
        stroke={color}
        strokeLinecap="round"
      />
      <circle
        cx={12}
        cy={6}
        r={2.5}
        stroke={color}
      />
      <circle
        cx={6.6}
        cy={12.8}
        r={2.5}
        stroke={color}
      />
      <circle
        cx={17.4}
        cy={12.8}
        r={2.5}
        stroke={color}
      />
    </svg>
  );
});

Group.displayName = 'Group';

export default Group;
