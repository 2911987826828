import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const UnorderedList = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      fill="none"
      height={25}
      ref={ref}
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle
        cx={6.5}
        cy={8.261}
        fill={color}
        r={0.87}
      />
      <path
        d="M10 8.262h8"
        stroke={color}
        strokeLinecap="round"
      />
      <circle
        cx={6.5}
        cy={12.001}
        fill={color}
        r={0.87}
      />
      <path
        d="M10 12h8"
        stroke={color}
        strokeLinecap="round"
      />
      <circle
        cx={6.5}
        cy={15.741}
        fill={color}
        r={0.87}
      />
      <path
        d="M10 15.742h8"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
});

UnorderedList.displayName = 'UnorderedList';

export default UnorderedList;
