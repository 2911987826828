import { forwardRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import styles from './SwitchStyles';

type Props = {
  className?: string;
  disabled?: boolean;
  on?: boolean;
};

const useStyles = makeStyles({ name: 'Switch' })(styles);

const Switch = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className = '',
    disabled = false,
    on = false,
  } = props;
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(
        classes.root,
        { [classes.disabled]: disabled, [classes.on]: on, [classes.disabledOn]: on && disabled },
        className,
      )}
      ref={ref}
    >
      <div className={classes.track}>
        <span className={classes.knob} />
      </div>
    </div>
  );
});

Switch.displayName = 'Switch';

export default Switch;
