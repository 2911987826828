import { forwardRef } from 'react';

import type { SVGProps } from 'react';

const JiraLogo = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M21.754 11.757L12.86 2.862 11.997 2 5.301 8.696l-3.062 3.061a.82.82 0 000 1.158l6.117 6.117 3.641 3.64 6.695-6.696.105-.104 2.957-2.953a.818.818 0 000-1.162zm-9.757 3.635l-3.056-3.056 3.056-3.057 3.056 3.057-3.056 3.056z"
      fill="#2684FF"
    />
    <path
      d="M11.997 9.28a5.145 5.145 0 01-.022-7.255L5.287 8.71l3.64 3.64 3.07-3.07z"
      fill="url(#prefix__paint0_linear)"
    />
    <path
      d="M15.06 12.328l-3.063 3.064a5.147 5.147 0 010 7.278l6.706-6.703-3.642-3.64z"
      fill="url(#prefix__paint1_linear)"
    />
    <defs>
      <linearGradient
        id="prefix__paint0_linear"
        x1={11.45}
        y1={6.187}
        x2={7.182}
        y2={10.454}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.18} stopColor="#0052CC" />
        <stop offset={1} stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="prefix__paint1_linear"
        x1={780.871}
        y1={899.391}
        x2={1216.69}
        y2={1181.93}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.18} stopColor="#0052CC" />
        <stop offset={1} stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
));

JiraLogo.displayName = 'JiraLogo';

export default JiraLogo;
