import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Collapse = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="m6.385 13.527 4.903.006.013 4.91M10.75 13.995l-4 4M18.699 10.968l-4.904-.006-.013-4.91M14.333 10.5l4-4"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Collapse.displayName = 'Collapse';

export default Collapse;
