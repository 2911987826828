import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const SaveTemplate = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...otherProps}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <rect x={3.5} y={3.5} width={17} height={17} rx={1.5} stroke={color} />
      <rect x={6.5} y={6.5} width={4} height={4} rx={0.5} stroke={color} />
      <rect x={6.5} y={12.5} width={4} height={5} rx={0.5} stroke={color} />
      <rect x={12.5} y={6.5} width={5} height={11} rx={0.5} stroke={color} />
    </svg>
  );
});

SaveTemplate.displayName = 'SaveTemplate';

export default SaveTemplate;
