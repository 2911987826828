import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Template = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      fill="none"
      height={32}
      ref={ref}
      viewBox="0 0 44 44"
      width={32}
      {...otherProps}
    >
      <rect
        height={28.333}
        rx={1.5}
        stroke={color}
        width={23}
        x={10.5}
        y={7.833}
      />
      <path
        d="M17.591 8v28M17.909 19.5h15.546"
        stroke={color}
      />
    </svg>
  );
});

Template.displayName = 'Template';

export default Template;
