import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const ClipboardFilled = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <line x1={8} y1={9.5} x2={15} y2={9.5} stroke={color} strokeLinecap="round" />
      <line x1={8} y1={11.5} x2={15} y2={11.5} stroke={color} strokeLinecap="round" />
      <line x1={8} y1={13.5} x2={11} y2={13.5} stroke={color} strokeLinecap="round" />
      <rect x={8.5} y={3.5} width={7} height={2} rx={0.5} stroke={color} />
      <path
        d="M8.5 4.5H7.5C6.39543 4.5 5.5 5.39543 5.5 6.5V18.5C5.5 19.6046 6.39543 20.5 7.5 20.5H16.5C17.6046 20.5 18.5 19.6046 18.5 18.5V6.5C18.5 5.39543 17.6046 4.5 16.5 4.5H15.5"
        stroke={color}
      />
    </svg>
  );
});

ClipboardFilled.displayName = 'ClipboardFilled';

export default ClipboardFilled;
