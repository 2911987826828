import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const PageBreak = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      fill="none"
      height={25}
      ref={ref}
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M6 5.049v3.605c0 .773.741 1.395 1.662 1.395h8.676c.92 0 1.662-.622 1.662-1.395V5.049M18 19.049v-3.605c0-.773-.741-1.395-1.662-1.395H7.662c-.92 0-1.662.622-1.662 1.395v3.605"
      />
      <path
        stroke={color}
        strokeDasharray="2 2"
        strokeLinecap="round"
        d="M4 12.049h16"
      />
    </svg>
  );
});

PageBreak.displayName = 'PageBreak';

export default PageBreak;
