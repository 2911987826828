import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Expand = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="m11.365 18.046-4.903-.006-.013-4.91M7 17.578l4-4M13.051 6.449l4.903.005.014 4.911M17.416 6.916l-4 4"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Expand.displayName = 'Expand';

export default Expand;
