import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const GroupBy = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <rect
        x={5}
        y={6}
        width={14}
        height={4}
        rx={1}
        stroke={color}
        strokeWidth={2}
      />
      <rect
        x={5}
        y={14}
        width={14}
        height={4}
        rx={1}
        stroke={color}
        strokeWidth={2}
      />
    </svg>
  );
});

GroupBy.displayName = 'GroupBy';

export default GroupBy;
