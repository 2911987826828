import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Letter = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      fill="none"
      height={25}
      ref={ref}
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        fill={color}
        d="M9.67 14.759H6.648l-1.06 2.29H3.874L8.74 7.096h1.429l1.36 9.953H9.916l-.246-2.29ZM7.29 13.35h2.235l-.423-3.924L7.29 13.35ZM17.47 17.049a1.567 1.567 0 0 1-.062-.37 3.361 3.361 0 0 1 .007-.368 8.95 8.95 0 0 1-.321.266 3.036 3.036 0 0 1-1.046.513c-.246.068-.508.1-.786.096a2.695 2.695 0 0 1-.882-.158 2.153 2.153 0 0 1-.718-.437 1.853 1.853 0 0 1-.485-.663 1.944 1.944 0 0 1-.144-.889c.014-.323.075-.608.185-.854a2.337 2.337 0 0 1 1.066-1.121c.237-.128.488-.23.752-.308.264-.078.538-.132.82-.164.283-.037.563-.055.841-.055l1.21.014.09-.513c.026-.2.015-.378-.035-.533a.914.914 0 0 0-.232-.403.977.977 0 0 0-.397-.247c-.16-.059-.34-.09-.54-.095a1.829 1.829 0 0 0-.506.055c-.16.04-.3.107-.424.198a1.08 1.08 0 0 0-.321.321 1.255 1.255 0 0 0-.185.451l-1.592.007c.031-.392.148-.732.348-1.019.205-.291.46-.533.766-.724.3-.187.633-.324.998-.41.37-.091.731-.135 1.087-.13.378.009.736.07 1.073.184.337.11.629.272.875.486.242.214.424.478.547.793.128.314.17.676.13 1.087l-.54 3.288c-.041.264-.069.53-.082.8-.01.264.025.524.102.779l-.006.123H17.47Zm-1.825-1.17c.205.005.403-.017.594-.067a2.236 2.236 0 0 0 .93-.5c.128-.109.241-.23.342-.362l.253-1.374-.937-.007c-.21 0-.428.016-.656.048a2.667 2.667 0 0 0-.643.171c-.11.055-.214.114-.314.178-.1.064-.19.139-.267.225a1.294 1.294 0 0 0-.294.684c-.018.164-.007.31.034.438a.706.706 0 0 0 .199.307c.09.082.2.146.328.192.132.04.276.063.43.068Z"
      />
    </svg>
  );
});

Letter.displayName = 'Letter';

export default Letter;
