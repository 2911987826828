import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Subtask = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M4 4v6a2 2 0 0 0 2 2h3"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M4 8v8a2 2 0 0 0 2 2h3"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <rect
        height={3}
        rx={0.5}
        stroke={color}
        strokeWidth={strokeWidth}
        width={12}
        x={8.5}
        y={10.5}
      />
      <rect
        height={3}
        rx={0.5}
        stroke={color}
        strokeWidth={strokeWidth}
        width={12}
        x={8.5}
        y={16.5}
      />
    </svg>
  );
});

Subtask.displayName = 'Subtask';

export default Subtask;
