import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const TemplateSquare = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <rect
        height={15}
        rx={1.5}
        stroke={color}
        width={15}
        x={4.5}
        y={4.5}
      />
      <path
        d="M9.5 5v14M10 10.5h9"
        stroke={color}
      />
    </svg>
  );
});

TemplateSquare.displayName = 'TemplateSquare';

export default TemplateSquare;
