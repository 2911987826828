import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const BlankSheet = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M13.338 9.333V4H6.813A1.81 1.81 0 005 5.813v12.374A1.81 1.81 0 006.813 20h9.467a1.81 1.81 0 001.813-1.813V9.333h-4.755z"
        stroke={color}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M12.813 4h1.565l3.707 4.427v.906"
        stroke={color}
        strokeMiterlimit={10}
        vectorEffect="non-scaling-stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

BlankSheet.displayName = 'BlankSheet';

export default BlankSheet;
