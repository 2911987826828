import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Clock = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <circle
        cx={12}
        cy={12}
        r={9}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M12 8.5v4l3 2.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Clock.displayName = 'Clock';

export default Clock;
