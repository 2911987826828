import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Description = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <rect
        fill={color}
        height={1}
        rx={0.5}
        strokeWidth={strokeWidth}
        width={16}
        x={4}
        y={5}
      />
      <rect
        fill={color}
        height={1}
        rx={0.5}
        strokeWidth={strokeWidth}
        width={16}
        x={4}
        y={9}
      />
      <rect
        fill={color}
        height={1}
        rx={0.5}
        strokeWidth={strokeWidth}
        width={16}
        x={4}
        y={13}
      />
      <rect
        fill={color}
        height={1}
        rx={0.5}
        strokeWidth={strokeWidth}
        width={10}
        x={4}
        y={17}
      />
    </svg>
  );
});

Description.displayName = 'Description';

export default Description;
