import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const SalesforceIcon = forwardRef<SVGSVGElement, Props>((props, ref) => (
  <svg
    fill="none"
    height="18"
    ref={ref}
    viewBox="0 0 26 18"
    width="26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.52799 14.9103C5.27789 14.9561 5.02015 14.98 4.75683 14.98C2.40589 14.98 0.500076 13.0742 0.500076 10.7232C0.500076 9.1402 1.36422 7.75899 2.64652 7.02558C2.39577 6.43902 2.25697 5.79318 2.25697 5.11493C2.25697 2.42814 4.43505 0.250061 7.12184 0.250061C8.67319 0.250061 10.0549 0.976212 10.9457 2.10716C11.7428 1.2947 12.8531 0.79068 14.0811 0.79068C15.7499 0.79068 17.2014 1.72146 17.9446 3.09219C18.5865 2.82792 19.2896 2.68216 20.0269 2.68216C23.0495 2.68216 25.4998 5.13249 25.4998 8.15513C25.4998 11.1778 23.0495 13.6281 20.0269 13.6281C19.7059 13.6281 19.3915 13.6005 19.0857 13.5475C18.3614 14.7308 17.0568 15.5204 15.5677 15.5204C14.9667 15.5204 14.3957 15.3918 13.8808 15.1605C13.1691 16.7279 11.5902 17.8178 9.75672 17.8178C7.82717 17.8178 6.17958 16.6107 5.52799 14.9103Z"
      fill="#00A1E1"
      fillRule="evenodd"
    />
  </svg>
));

SalesforceIcon.displayName = 'SalesforceIcon';

export default SalesforceIcon;
