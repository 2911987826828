import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Container = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M4.727 16.364h14.546v2.909H4.727v-2.91Zm-.727 0v2.909c0 .4.327.727.727.727h14.546a.73.73 0 0 0 .727-.727v-2.91a.73.73 0 0 0-.727-.727H4.727a.73.73 0 0 0-.727.728ZM4.727 10.545h14.546v2.91H4.727v-2.91Zm-.727 0v2.91c0 .4.327.727.727.727h14.546a.73.73 0 0 0 .727-.728v-2.909a.73.73 0 0 0-.727-.727H4.727a.73.73 0 0 0-.727.727ZM4.727 4.727h14.546v2.909H4.727v-2.91Zm-.727 0v2.909c0 .4.327.727.727.727h14.546A.73.73 0 0 0 20 7.636v-2.91A.73.73 0 0 0 19.273 4H4.727A.73.73 0 0 0 4 4.727Z"
        fill={color}
      />
    </svg>
  );
});

Container.displayName = 'Container';

export default Container;
