import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const BatonInitial = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        fill={color}
        d="M14.448 7.607c1.054-.593 1.605-1.846 1.605-2.792v-.66C16.053 1.407 14.164 0 10.714 0h-5.89c-.1 0-.18.08-.18.18v4.673c0 .1.08.18.18.18h2.85c.1 0 .18-.08.18-.18v-2.19c0-.1.08-.18.18-.18h2.721c1.428 0 2.067.506 2.067 1.958v.22c0 1.385-.615 2.001-1.54 2.001H1.594a.178.178 0 0 0-.167.117L.71 8.709a.178.178 0 0 0 .167.24h10.605c1.122 0 1.714.726 1.714 1.824v.417c0 1.01-.57 1.736-1.935 1.736H8.034a.18.18 0 0 1-.18-.18v-1.993c0-.1-.08-.18-.18-.18h-2.85c-.1 0-.18.08-.18.18v4.453c0 .1.08.18.18.18h6.48c3.494 0 5.099-1.67 5.099-3.955v-.77c0-1.406-.901-2.612-1.955-3.054Z"
      />
    </svg>
  );
});

BatonInitial.displayName = 'BatonInitial';

export default BatonInitial;
