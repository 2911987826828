import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const SmallDownload = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M20 20H4"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M17.497 11.745 12.255 17 7 11.745M12.255 17V4"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

SmallDownload.displayName = 'SmallDownload';

export default SmallDownload;
