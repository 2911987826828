import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const CloseDrawer = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth, ...otherProps } = props;
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="m11.272 6.751 5.254 5.243-5.254 5.255M16 12H5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
      <path
        d="M19.5 5.5v13"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

CloseDrawer.displayName = 'CloseDrawer';

export default CloseDrawer;
