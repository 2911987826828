import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const User = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M16.286 8.267a3.776 3.776 0 0 1-3.786 3.766 3.776 3.776 0 0 1-3.786-3.766A3.776 3.776 0 0 1 12.5 4.5a3.776 3.776 0 0 1 3.786 3.767z"
        stroke={color}
      />
      <path
        d="M12.5 12.533C8.358 12.533 5 15.876 5 20h15c0-4.124-3.358-7.467-7.5-7.467z"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
});

User.displayName = 'User';

export default User;
