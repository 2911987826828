import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const DependencyBadgeTwoWay = forwardRef<SVGSVGElement, Props>((props, ref) => (
  <svg
    width={28}
    height={24}
    viewBox="0 0 28 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle cx={7} cy={12} r={7} fill="#FFA9BB" />
    <path
      d="M18.059 12.627L22 16.56 18.059 20.5M22 16.56H10"
      stroke="#219653"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.941 10.873L10 6.941l3.941-3.94M10 6.941h12"
      stroke="#FF3C64"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

DependencyBadgeTwoWay.displayName = 'DependencyBadgeTwoWay';

export default DependencyBadgeTwoWay;
