import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type IconClasses = {
  check?: string
  circle?: string
};

type Props = SVGProps<SVGSVGElement> & {
  checked?: boolean
  classes?: IconClasses
  color?: string
  disabled?: boolean
};

const disabledColor = '#D5D5D5';

const Radio = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { color = 'currentColor', classes, checked = false, disabled = false, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      data-cy={checked ? 'radio-checked' : 'radio-unchecked'}
      ref={ref}
      {...otherProps}
    >
      <path
        d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="rotate(-145 12 12)"
        strokeDashoffset={checked ? 7 : 0}
        strokeDasharray={50.27256393432617}
        className={(classes && classes.circle) && classes.circle}
        fill={disabled ? disabledColor : 'transparent'}
      />
      <path
        d="M8.16 10.809l3.813 3.822L20 6.321"
        stroke={checked ? color : 'transparent'}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={(classes && classes.check) && classes.check}
      />
    </svg>
  );
});

Radio.displayName = 'Radio';

export default Radio;
