import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Text = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M21 11.01 3 11v2h18v-1.99ZM3 16h12v2H3v-2ZM21 6H3v2.01L21 8V6Z"
        fill={color}
      />
    </svg>
  );
});

Text.displayName = 'Text';

export default Text;
