import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & { color?: string; checked?: boolean };

const CircleCheck = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { color = 'currentColor', checked = true, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      {checked ? (
        <>
          <circle cx={12} cy={12} r={12} fill={color} />
          <path
            d="M6 12.032L10.11 16 18 8"
            stroke="#fff"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : (
        <circle
          cx={12}
          cy={12}
          r={11}
          stroke={color}
          strokeWidth={2}
        />
      )}
    </svg>
  );
});

CircleCheck.displayName = 'CircleCheck';

export default CircleCheck;
