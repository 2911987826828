import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const TaskComplete = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M17.111 5.849A7.947 7.947 0 0012 4a8 8 0 108 8c0-.693-.089-1.36-.249-2"
        stroke={color}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16 10.809l3.813 3.822L20 6.321"
        stroke={color}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

TaskComplete.displayName = 'TaskComplete';

export default TaskComplete;
