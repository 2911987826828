import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const DependencyBadgeChild = forwardRef<SVGSVGElement, Props>((props, ref) => (
  <svg
    width={30}
    height={24}
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle
      cx={7}
      cy={12}
      r={7}
      transform="rotate(180 7 12)"
      fill="#FFA9BB"
    />
    <path
      d="M15.255 17.497L10 12.255 15.255 7M10 12.255h16"
      stroke="#FF3C64"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

DependencyBadgeChild.displayName = 'DependencyBadgeChild';

export default DependencyBadgeChild;
