import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & {
  color?: string
  direction?: 'up' | 'down' | 'left' | 'right'
  strokeWidth?: number
};

const rotationMap = {
  left: 180,
  right: 0,
  down: 90,
  up: -90,
};

const DoubleChevron = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { color = 'currentColor', direction = 'right', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      style={{
        transformOrigin: 'center',
        transition: 'transform 0.25s',
        transform: `rotate(${rotationMap[direction]}deg)`,
      }}
      ref={ref}
      {...otherProps}
    >
      <path
        d="M7 6L13 11.9937L7 18"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit={10}
      />
      <path
        d="M12 6L18 11.9937L12 18"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit={10}
      />
    </svg>
  );
});

DoubleChevron.displayName = 'DoubleChevron';

export default DoubleChevron;
