import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const DependencyBadgeParent = forwardRef<SVGSVGElement, Props>((props, ref) => (
  <svg
    width={30}
    height={24}
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle cx={23} cy={12} r={7} fill="#6FCF97" />
    <path
      d="M14.745 6.503L20 11.745 14.745 17M20 11.745H4"
      stroke="#219653"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

DependencyBadgeParent.displayName = 'DependencyBadgeParent';

export default DependencyBadgeParent;
