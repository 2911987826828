import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Edit = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="m5.156 14.802-.952 3.09-.08.29c-.12.41-.25 1.2.12 1.57.371.37 1.153.25 1.574.12l.29-.08 3.137-.95 8.138-8.12-4.079-4.082-8.148 8.162ZM20 7.59c0-.33-.12-.66-.37-.91l-2.316-2.31c-.25-.25-.581-.37-.912-.37-.33 0-.661.12-.872.37l-2.175 2.18 4.09 4.081 2.184-2.18c.25-.2.371-.53.371-.86Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Edit.displayName = 'Edit';

export default Edit;
