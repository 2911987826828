import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Dot = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <circle cx={10} cy={10} r={6} fill={color} />
    </svg>
  );
});

Dot.displayName = 'Dot';

export default Dot;
