import { forwardRef } from 'react';
import type { SVGProps } from 'react';

import { palette } from "theme";

type Props = SVGProps<SVGSVGElement>;

const Form = forwardRef<SVGSVGElement, Props>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={49}
    height={49}
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      fill={palette.primary.main}
      d="M11.334 11.75a3 3 0 0 1 3-3h20a3 3 0 0 1 3 3v21.5c-3 1.5-5.843 7.5-7.5 7.5h-15.5a3 3 0 0 1-3-3v-26Z"
    />
    <path
      fill={palette.primary.light}
      d="M29.334 32.75h8v.172c0 .492-.097.977-.283 1.422a3.327 3.327 0 0 1-.796 1.153l-4.813 4.48c-.537.5-1.214.773-1.913.773h-.195v-8Z"
    />
    <rect width={12} height={4} x={18.334} y={7.75} fill={palette.primary.light} rx={2} />
  </svg>
));

Form.displayName = 'Form';

export default Form;
