import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const CustomFields = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M4.58281 7.99489L3.92732 5.62511C3.82998 5.27321 4.1304 4.93903 4.49064 4.99849L7.11897 5.43229C7.2223 5.44934 7.31768 5.49842 7.39163 5.57259L18.8537 17.0688C20.3281 18.8455 17.614 21.086 15.9511 19.695L4.70745 8.2114C4.64825 8.15094 4.60537 8.07644 4.58281 7.99489Z"
        stroke={color}
      />
      <path
        d="M10.5264 16.787L7.23981 20.1842C5.38045 21.8964 2.55762 18.6543 4.40032 17.2062L7.89402 14.0866M15.3757 10.8758L15.9897 10.3384C16.1035 10.2388 16.2552 10.1975 16.4053 10.2154C17.9482 10.3994 20.4743 9.90827 20.6312 6.81141C20.6523 6.39485 20.1608 6.20573 19.8596 6.49423L18.4968 7.79939C18.3004 7.98748 17.9897 7.98413 17.7974 7.79184L16.7905 6.78494C16.5991 6.5935 16.5948 6.28447 16.7808 6.08779L18.1248 4.66703C18.4181 4.35691 18.2117 3.86728 17.7877 3.91639C16.0153 4.12162 13.8682 5.0773 14.3442 7.84225C14.3755 8.02447 14.3162 8.2135 14.1765 8.33454L13.4657 8.95017"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
});

CustomFields.displayName = 'CustomFields';

export default CustomFields;
