import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Phase = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path d="M4 6h16l-6 6 6 6H4V6z" fill={color} />
    </svg>
  );
});

Phase.displayName = 'Phase';

export default Phase;
