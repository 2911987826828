import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & {
  color?: string
  opacity?: number
  radius?: number
};

const Question = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { color = 'currentColor', opacity = 0.6, radius = 6.167, ...otherProps } = props;

  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <circle cx={10} cy={10} r={radius} stroke={color} />
      <path
        d="M9.815 11.915c-.282 0-.514-.232-.48-.512.025-.203.063-.377.116-.521.091-.253.278-.533.56-.84l.72-.742c.307-.348.461-.722.461-1.12 0-.385-.1-.686-.302-.902-.202-.22-.495-.33-.879-.33-.374 0-.674.1-.9.297a.959.959 0 00-.238.307c-.121.25-.33.49-.608.49-.282 0-.517-.234-.45-.507.091-.365.281-.674.57-.927.417-.366.96-.55 1.626-.55.692 0 1.23.188 1.615.561.388.37.582.879.582 1.527 0 .641-.296 1.273-.89 1.895l-.598.594c-.18.2-.3.46-.36.778-.05.274-.267.502-.545.502zm-.557 1.74c0-.164.05-.301.149-.411.102-.114.252-.17.45-.17s.348.056.45.17a.58.58 0 01.154.412.58.58 0 01-.153.412c-.103.106-.253.16-.45.16-.199 0-.349-.054-.451-.16a.593.593 0 01-.149-.412z"
        fill={color}
      />
    </svg>
  );
});

Question.displayName = 'Question';

export default Question;
