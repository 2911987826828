import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Archive = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M4.5 7.5H19.5V18C19.5 18.8284 18.8284 19.5 18 19.5H6C5.17157 19.5 4.5 18.8284 4.5 18V7.5Z"
        stroke={color}
      />
      <rect
        x="3.5"
        y="4.5"
        width="17"
        height="3"
        rx="0.5"
        stroke={color}
      />
      <line
        x1="8.5"
        y1="10.5"
        x2="15.5"
        y2="10.5"
        strokeLinecap="round"
        stroke={color}
      />
    </svg>
  );
});

Archive.displayName = 'Archive';

export default Archive;
