import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Sort = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth, ...otherProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="m6.368 10.259 5.66-5.28 5.59 5.28H6.367ZM17.617 13.72 11.957 19l-5.589-5.28h11.25Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Sort.displayName = 'Sort';

export default Sort;
